<template>
    <v-container>
        <v-row class="my-10">
            <v-col cols="12" class="d-md-flex">
                <h2 class="primary--text main-heading">
                    My Networking Opportunities
                </h2>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary primary--text"
                    dark
                    class="hover mb-2 pa-7"
                    :to="{ name: 'NetworkingOpportunitiesCreate' }"
                >
                    <v-icon small left> fa fa-plus </v-icon>
                    Create New
                </v-btn>
            </v-col>
            <v-col
                v-if="
                    getMyOpportunitiesStatus_Pending ||
                    getMyOpportunitiesStatus_Idle
                "
                style="min-height: 400px"
            >
                <loading-spinner></loading-spinner>
            </v-col>
            <v-col v-else-if="getMyOpportunitiesStatus_Error" cols="12">
                <span class="text-h6">
                    {{ $t("ErrorMessages.somethingWrong") }}
                </span>
            </v-col>
            <template v-else-if="getMyOpportunitiesStatus_Success">
                <v-col cols="12">
                    <h2 class="primary--text main-heading">Draft</h2>
                </v-col>
                <v-col v-if="draftOpportunities.length <= 0" cols="12">
                    You don't have any draft requests/provisions
                </v-col>
                <v-col
                    v-for="item in draftOpportunities"
                    :key="item.id"
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <networking-opportunities-card
                        :item="item"
                        has-actions
                        @edit-item="handleEdit(item.id)"
                        @delete-item="handleDelete(item.id)"
                        @click="
                            $router.push({
                                name: 'NetworkingOpportunity',
                                params: { id: item.id },
                            })
                        "
                    ></networking-opportunities-card>
                </v-col>
                <v-col cols="12">
                    <h2 class="primary--text main-heading">Public</h2>
                </v-col>
                <v-col v-if="publicOpportunities.length <= 0" cols="12">
                    You don't have any public requests/provisions
                </v-col>
                <v-col
                    v-for="item in publicOpportunities"
                    :key="item.id"
                    cols="12"
                    sm="6"
                    md="4"
                >
                    <networking-opportunities-card
                        :item="item"
                        has-actions
                        @edit-item="handleEdit(item.id)"
                        @delete-item="handleDelete(item.id)"
                        @click="
                            $router.push({
                                name: 'NetworkingOpportunity',
                                params: { id: item.id },
                            })
                        "
                    ></networking-opportunities-card>
                </v-col>
                <confirm-modal :open="openModal" :close.sync="openModal">
                    <template #content>
                        <span class="modal-content">
                            {{
                                $t(
                                    `SME.SolutionCard.solutionDeleteModal.confirmText`
                                )
                            }}
                        </span>
                    </template>
                    <template #action>
                        <v-btn
                            depressed
                            dense
                            color="error lighten-1"
                            class="mr-2"
                            :loading="false"
                            @click="confirmDelete()"
                        >
                            {{
                                $t(
                                    `SME.SolutionCard.solutionDeleteModal.confirmDelete`
                                )
                            }}
                        </v-btn>
                    </template>
                </confirm-modal>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { apiStatus } from "../api/constants/apiStatus";
import { apiStatusComputed } from "../api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import {
    getUserOpportunities,
    deleteOpportunity,
} from "@/api/networkingOpportunitiesApi.js";

import NetworkingOpportunitiesCard from "./NetworkingOpportunitiesCard.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import ConfirmModal from "@/components/ConfirmModal";

export default {
    name: "NetworkingOpportunities",
    components: {
        NetworkingOpportunitiesCard,
        LoadingSpinner,
        ConfirmModal,
    },

    data() {
        return {
            publicOpportunities: [],
            draftOpportunities: [],

            deletingItemId: null,
            openModal: false,

            getMyOpportunitiesStatus: apiStatus.Idle,
            deleteItemStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getMyOpportunitiesStatus", "deleteItemStatus"]),
    },
    methods: {
        async handleUsersOpportunities() {
            this.getMyOpportunitiesStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getUserOpportunities);

            if (error) {
                this.getMyOpportunitiesStatus = apiStatus.Error;
                return;
            }

            this.publicOpportunities = response.data.data.filter(
                (item) => item.status == 1
            );
            this.draftOpportunities = response.data.data.filter(
                (item) => item.status != 1
            );
            this.getMyOpportunitiesStatus = apiStatus.Success;
        },

        handleEdit(id) {
            this.$router.push({
                name: "NetworkingOpportunitiesUpdate",
                params: {
                    id,
                },
            });
        },

        handleDelete(id) {
            this.deletingItemId = id;
            this.openModal = true;
        },

        async confirmDelete() {
            this.deleteItemStatus = apiStatus.Pending;
            const payload = {
                id: this.deletingItemId,
            };

            const { response, error } = await withAsync(
                deleteOpportunity,
                payload
            );

            if (error) {
                this.deleteItemStatus = apiStatus.Error;
                return;
            }

            this.deleteItemStatus = apiStatus.Success;
            this.deletingItemId = null;
            this.openModal = false;
        },
    },

    created() {
        this.handleUsersOpportunities();
    },
};
</script>
