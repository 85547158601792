var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"my-10"},[_c('v-col',{staticClass:"d-md-flex",attrs:{"cols":"12"}},[_c('h2',{staticClass:"primary--text main-heading"},[_vm._v(" My Networking Opportunities ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-btn',{staticClass:"hover mb-2 pa-7",attrs:{"color":"secondary primary--text","dark":"","to":{ name: 'NetworkingOpportunitiesCreate' }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fa fa-plus ")]),_vm._v(" Create New ")],1)],1),(
                _vm.getMyOpportunitiesStatus_Pending ||
                _vm.getMyOpportunitiesStatus_Idle
            )?_c('v-col',{staticStyle:{"min-height":"400px"}},[_c('loading-spinner')],1):(_vm.getMyOpportunitiesStatus_Error)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("ErrorMessages.somethingWrong"))+" ")])]):(_vm.getMyOpportunitiesStatus_Success)?[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"primary--text main-heading"},[_vm._v("Draft")])]),(_vm.draftOpportunities.length <= 0)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" You don't have any draft requests/provisions ")]):_vm._e(),_vm._l((_vm.draftOpportunities),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('networking-opportunities-card',{attrs:{"item":item,"has-actions":""},on:{"edit-item":function($event){return _vm.handleEdit(item.id)},"delete-item":function($event){return _vm.handleDelete(item.id)},"click":function($event){return _vm.$router.push({
                            name: 'NetworkingOpportunity',
                            params: { id: item.id },
                        })}}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"primary--text main-heading"},[_vm._v("Public")])]),(_vm.publicOpportunities.length <= 0)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" You don't have any public requests/provisions ")]):_vm._e(),_vm._l((_vm.publicOpportunities),function(item){return _c('v-col',{key:item.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('networking-opportunities-card',{attrs:{"item":item,"has-actions":""},on:{"edit-item":function($event){return _vm.handleEdit(item.id)},"delete-item":function($event){return _vm.handleDelete(item.id)},"click":function($event){return _vm.$router.push({
                            name: 'NetworkingOpportunity',
                            params: { id: item.id },
                        })}}})],1)}),_c('confirm-modal',{attrs:{"open":_vm.openModal,"close":_vm.openModal},on:{"update:close":function($event){_vm.openModal=$event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{staticClass:"modal-content"},[_vm._v(" "+_vm._s(_vm.$t( "SME.SolutionCard.solutionDeleteModal.confirmText" ))+" ")])]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","dense":"","color":"error lighten-1","loading":false},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t( "SME.SolutionCard.solutionDeleteModal.confirmDelete" ))+" ")])]},proxy:true}])})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }